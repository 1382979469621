<template>
  <div class="byline">
    <user-avatar
      v-if="showUserAvatar"
      :user="user"
      :is-cross-published="isCrossPublished"
      :original-site="originalSite"
      aria-haspopup="true"
      class="byline__avatar"
    />
    <div class="byline__content">
      <p class="byline__author">
        <span v-if="showPreposition">
          By
        </span>
        <span v-if="displayName === 'Editorial Team'">
          <a href="https://health-union.com/editorial-team/"
             target="_blank"
             rel="noopener noreferrer nofollow"
          >
            {{ displayName }}
          </a>
        </span>
        <span
          v-else
          class="byline__name"
          :class="{ 'byline__name--with-tooltip link': profileTooltipEnabled }"
          :tabindex="profileTooltipEnabled ? '0' : ''"
          aria-haspopup="true"
          @click="openUserProfileTooltip"
          @keyup.enter="openUserProfileTooltip"
        >
          {{ displayName }}</span><!-- on one line to prevent extra underlined whitespace -->
        <span
          v-if="roleText"
          class="byline__role"
        >
          {{ roleText }}
        </span>
      </p>
      <p v-if="date" class="byline__timestamp">
        Last Updated: <time-stamp :date="date" :show-date-stamp="showDate" />
      </p>
      <p v-if="readTime > 0" class="byline__read-time">
        <span>{{ readTime }} min read</span>
      </p>
      <slot name="after" />
    </div>
  </div>
</template>

<script>
import UserAvatar from '@/components/User/UserAvatar.vue';
import TimeStamp from '@/components/Global/TimeStamp.vue';
import openUserProfileTooltipMixin from '@/mixins/open-user-profile-tooltip-mixin';
import { isFeatureFlagEnabled } from '@/utils/utils';

export default {
  name: 'UserByline',
  components: {
    UserAvatar,
    TimeStamp,
  },
  mixins: [openUserProfileTooltipMixin],
  props: {
    user: {
      type: Object,
      default: () => {},
    },
    date: {
      type: String,
      default: '',
    },
    showDate: {
      type: Boolean,
      default: false,
    },
    displayTooltip: {
      type: Boolean,
      default: true,
    },
    showUserAvatar: {
      type: Boolean,
      default: true,
    },
    showPreposition: {
      type: Boolean,
      default: true,
    },
    isCrossPublished: {
      type: Boolean,
      default: false,
    },
    originalSite: {
      type: String,
      default: null,
    },
    postType: {
      type: String,
      default: null,
    },
    wordCount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      badges: {
        admin: 'Community Admin',
        moderator: 'Moderator',
        contributor: 'Contributor',
        member: 'Member',
        moderator_contributor: 'Moderator & Contributor',
        null: 'Member',
      },
    };
  },
  computed: {
    roleText() {
      return this.user && this.badges[this.user.badge];
    },
    readTime() {
      if (this.wordCount > 0 && isFeatureFlagEnabled(this.$site, 'read_time') && ['post', 'page', 'story'].includes(this.postType)) {
        // avg adult wpm
        return Math.max(Math.round(this.wordCount / 238), 1);
      }
      return 0;
    },
    displayName() {
      return this.user ? this.user.username : 'not found';
    },
    showTooltip() {
      return this.user ? this.displayTooltip : false;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_byline';
</style>

<docs>

Can include timestamps

Moderators have an indicator in their byline

</docs>
