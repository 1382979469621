<template>
  <!-- eslint-disable vue/no-v-html -->
  <article
    class="post-teaser"
    :class="dynamicClasses"
    data-test-id="post-teaser"
  >
    <app-link
      v-if="shouldShowImage"
      :to="link"
      class="post-teaser__image-link link link--unstyled"
    >
      <post-teaser-image
        ref="teaserImage"
        :post="post"
        :post-title="postTitle"
        :responsive-images="responsiveImages"
        :featured-image-size="featuredImageSize"
        :secondary-image-size="secondaryImageSize"
        :loading="loading"
      />
    </app-link>
    <p
      v-if="showBookmarkType"
      class="post-teaser__bookmark-type"
    >
      {{ contentType }}
    </p>
    <span
      v-if="showHomepageEngagementLabel"
      class="post-teaser__homepage-engagement-label"
      data-test-id="post-teaser-homepage-engagement-label"
    >Featured story
    </span>
    <div class="post-teaser__content">
      <site-masthead
        v-if="showMasthead"
        :site-name="post.siteName"
        :href="link"
        class="masthead--teaser"
      />
      <cross-publishing-indicator
        v-if="showCrossPublishing"
        :prefixes="allSitesForPost"
        :post-path="post.permalink"
        :original-site="post.originalSite"
      />
      <component
        :is="makeHeadingH3 ? 'h3' : 'h2'"
        class="post-teaser__heading heading--3"
        data-test-id="post-teaser-heading"
      >
        <app-link
          :to="link"
          class="post-teaser__heading-link link link--unstyled"
          :style="styleObject"
        >
          <span v-html="postTitle" />
        </app-link>
      </component>
      <app-link
        v-if="displayOptions.showSummary && summary"
        :to="link"
        class="post-teaser__summary-link link--unstyled"
      >
        <p class="post-teaser__summary" :style="styleObject">
          <span v-html="summary" />
        </p>
      </app-link>
      <div v-if="showUserByline || showBookmarkButton" class="post-teaser__footer">
        <user-byline
          v-if="showUserByline"
          :user="post.author"
          :display-tooltip="!isSponsoredArticle"
          :show-user-avatar="!isSponsoredArticle"
          :post-type="post.type"
          :word-count="post.wordCount"
          :is-cross-published="isCrossPublished"
          :original-site="post.originalSite"
        />
        <bookmark-button v-if="showBookmarkButton" :bookmarkable="post" />
      </div>
      <engagement-bar
        v-if="showEngagement"
        :object-type="post.type"
        :the-object="post"
        :comment-count="post.commentCount"
        :is-condensed="false"
      />
    </div>
  </article>
</template>

<script>
import UserByline from '@/components/User/UserByline.vue';
import EngagementBar from '@/components/Engagement/EngagementBar.vue';
import BookmarkButton from '@/components/Bookmark/BookmarkButton.vue';
import SiteMasthead from '@/components/Footer/SiteMasthead.vue';
import PostTeaserImage from '@/components/Posts/PostTeaserImage.vue';
import { config } from '@/configuration/nexus-config';
import kebabCase from '@/utils/kebab-case';
import postTeaserMixin from '@/mixins/post-teaser-mixin';
import CrossPublishingIndicator from '@/components/Posts/CrossPublishingIndicator.vue';

export default {
  name: 'PostTeaser',
  components: {
    UserByline,
    EngagementBar,
    BookmarkButton,
    SiteMasthead,
    PostTeaserImage,
    CrossPublishingIndicator,
  },
  mixins: [postTeaserMixin],
  props: {
    /**
     * Recieves a post object which must contain a postId, heading, body, and author.
     * Post will also contain an options object that controls various display settings
     */
    post: {
      type: Object,
      required: true,
    },
    responsiveImages: {
      type: Boolean,
      default: false,
    },
    showBookmarkType: {
      type: Boolean,
      default: false,
    },
    makeHeadingH3: {
      type: Boolean,
      default: false,
    },
    contentType: {
      type: String,
      default: '',
    },
    /**
     * This prop is intended for use with parent communities where we want to show
     * the masthead for the sub community which this post belongs to.
     */
    showMasthead: {
      type: Boolean,
      default: false,
    },
    showHomepageEngagementLabel: {
      type: Boolean,
      default: false,
    },
    featuredImageSize: {
      type: String,
      default: null,
    },
    secondaryImageSize: {
      type: String,
      default: null,
    },
    loading: {
      type: String,
      default: 'lazy',
    },
  },
  computed: {
    displayOptions() {
      if (this.post.options) {
        return this.post.options;
      }
      return {
        showImage: true,
        showSummary: true,
        showByLine: !this.isSponsoredArticle,
        showEngagement: true,
      };
    },
    showUserByline() {
      return this.post.author && this.displayOptions.showByLine;
    },
    isCrossPublished() {
      return this.post?.crossPublishedSites?.length > 0;
    },
    isOriginalSite() {
      return !this.post.originalSite || this.post.originalSite === this.$site.prefix;
    },
    showEngagement() {
      return this.displayOptions.showEngagement
        && config.engagementTypes.includes(this.post.type)
        && this.isOriginalSite
        && !this.hasChildSiteTeasers; // from postTeaserHelpers mixin
    },
    addEllipses() {
      return !this.post.excerpt && !/\.\.\.$/.test(this.summaryContent);
    },
    isSearchResult() {
      return this.$route.name === 'search';
    },
    summaryContent() {
      // Note that posts from ElasticSearch truncate the content directly and do not
      // include an excerpt or excerptFromContent field
      return this.displayOptions.summary
        || this.post.excerpt
        || this.post.excerptFromContent
        || this.post.content;
    },
    summary() {
      if (this.summaryContent === null) {
        return '';
      }
      if (this.isSearchResult) {
        return `...${this.summaryContent}${this.addEllipses ? '...' : ''}`;
      }
      // Summary ends with a period
      // eslint-disable-next-line no-useless-escape
      if (!this.post.excerpt && /([^\.]+)(\.{1}$)/.test(this.summaryContent)) {
        return `${this.summaryContent}..`;
      }
      if (this.addEllipses) {
        return `${this.summaryContent}...`;
      }
      return this.summaryContent;
    },
    hasFeaturedImage() {
      return !!this.post.featuredMedia && !!this.post.featuredMedia[0];
    },
    hasSecondaryImage() {
      return !!this.post.secondaryImg && !!this.post.secondaryImg[0];
    },
    shouldShowImage() {
      if (!this.hasFeaturedImage && !this.hasSecondaryImage) { return false; }
      return this.displayOptions.showImage;
    },
    postTitle() {
      if (this.isSponsoredArticle) {
        return `Sponsored: ${this.post.title}`;
      }

      return this.post.title;
    },
    isSponsoredArticle() {
      return this.post.type === 'sponsored_article';
    },
    dynamicClasses() {
      const classes = [`post-teaser--${kebabCase(this.post.type)}`];
      if (this.post.status === 'draft') {
        classes.push('post-teaser--draft');
      }
      if (this.post.options && Object.keys(this.post.options).includes('floatText')) {
        classes.push(`post-teaser--${this.post.options.floatText}`);
      }
      if (this.hasLegacyRatioImage) {
        classes.push('post-teaser--legacy-image');
      }
      return classes;
    },
    styleObject() {
      if (this.post.options && this.post.options.textColor) {
        return { color: this.post.options.textColor };
      }
      return {};
    },
    showBookmarkButton() {
      const typesWithoutBookmarkButton = ['quiz', 'sponsored_article'];
      const typesWithoutBookmarkButtonOnHomepage = ['cas'];

      if (typesWithoutBookmarkButton.includes(this.post.type)) {
        return false;
      }
      if (
        this.$route.name === 'home'
        && typesWithoutBookmarkButtonOnHomepage.includes(this.post.type)
      ) {
        return false;
      }

      return true;
    },
    hasLegacyRatioImage() {
      let mediaData;
      let imageSize;

      if (this.hasFeaturedImage) {
        [mediaData] = this.post.featuredMedia;
        imageSize = this.featuredImageSize || 'FullSize';
      } else if (this.hasSecondaryImage) {
        [mediaData] = this.post.secondaryImg;
        imageSize = this.secondaryImageSize || this.featuredImageSize || 'FullSize';
      }

      if (!mediaData) return false;

      const teaserImageHeight = mediaData[`height${imageSize}`];
      const teaserImageWidth = mediaData[`width${imageSize}`];

      return teaserImageWidth >= teaserImageHeight * 3;
    },
    showCrossPublishing() {
      return this.post?.crossPublishedSites?.length > 0;
    },
    allSitesForPost() {
      if (!this.post) return [];

      return [
        this.post.originalSite,
        ...this.post.crossPublishedSites,
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_post-teaser';
</style>

<docs>
A summary of any wordpress post

Post teasers may not have an image

Post body is truncated after 100 characters.

</docs>
